export default class FileManager {
    static list(data) {
        return new Promise(((resolve, reject) => {
            window.go({
                url: "/boke/fileBoke/list", data: data
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }

    static deleteByIds(ids) {
        return new Promise(((resolve, reject) => {
            if (ids.length === 0) {
                resolve("")
            } else {
                window.go({
                    url: "/boke/fileBoke/deleteByIds", data: {
                        ids: ids
                    }
                }).then(res => {
                    resolve(res.result)
                }, error => {
                    reject(error)
                })
            }
        }))
    }

    static getFilesByNewNames(newNames) {
        return new Promise(((resolve, reject) => {
            window.go({
                url: "/boke/fileBoke/getFilesByNewNames", data: {
                    newNames: newNames
                }
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }

    static updateStatus(ids, status) {
        return new Promise(((resolve, reject) => {
            window.go({
                url: "/boke/fileBoke/updateStatus", data: {
                    ids: ids, status: status
                }
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }


}
