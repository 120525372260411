<template>
    <div class="container">
        <div class="container">
            <div class="header">
                <div class="item">
                    <span class="title">文件名:</span>
                    <el-input class="input" v-model="newName" clearable size="small"
                              placeholder="请输入文件名"></el-input>
                </div>
                <div class="item">
                    <span class="title">扩展名:</span>
                    <el-input class="input" v-model="extension" clearable size="small"
                              placeholder="请输入扩展名"></el-input>
                </div>
                <div class="item">
                    <span class="title">状态:</span>
                    <el-select v-model="status" size="small" @change="statusChangeAction" placeholder="请选择">
                        <el-option
                            v-for="item in statusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="tool-search">
                    <el-button size="small" icon="el-icon-refresh-left" type="primary" @click="resetAction">重置
                    </el-button>
                    <el-button size="small" icon="el-icon-search" type="primary" @click="searchAction">搜索</el-button>
                </div>
                <div class="tool-add">
                    <el-upload
                        class="avatar-uploader"
                        :action="config.baseURL +'boke/fileBoke/upload'"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                    >
                        <el-button size="small" icon="el-icon-plus" type="primary">上传</el-button>
                    </el-upload>
                </div>
            </div>
            <div class="content" :style="{height: screenHeight - 170 + 'px'}">
                <el-table
                    border
                    ref="multipleTable"
                    :data="tableData"
                    :height="screenHeight-170-55"
                    :header-cell-style="{backgroundColor: '#F9F9F9', color: '#555555', fontWeight: 'none'}"
                    :row-style="{height: '50px',fontSize: '14px',color: '#555555'}"
                    @selection-change="selectionChange"
                    style="width: 100%;cursor: pointer"
                >
                    <el-table-column
                        type="index"
                        fixed="left"
                        width="50"
                        :index="indexMethod">
                    </el-table-column>
                    <el-table-column
                        type="selection"
                        fixed="left"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        prop="relativePath"
                        label="名称"
                        width="250"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="extension"
                        label="扩展"
                        width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        label="状态"
                        width="80"
                    >
                        <template slot-scope="scope">
                            <span v-if="scope.row.status===0" style="color: red">已禁用</span>
                            <span v-else>正常</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="bokeInfo.title"
                        label="绑定文章标题"
                        width="250"
                    >
                        <template slot-scope="scope">
                            <span v-if="scope.row.bokeInfo===null" style="color: red">未绑定</span>
                            <span v-else>{{ scope.row.bokeInfo.title }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="cTime"
                        label="创建时间"
                        sortable
                        width="200">
                        <template slot-scope="scope">
                            <span>{{ moment(scope.row.cTime).format("yyyy-MM-DD HH:mm:ss") }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button
                                v-if="scope.row.status===1"
                                size="mini"
                                @click="handleForbidden(scope.row)">禁用
                            </el-button>
                            <el-button
                                v-if="scope.row.status===0"
                                size="mini"
                                @click="handleRecover(scope.row)">恢复
                            </el-button>
                            <el-button
                                v-if="scope.row.bokeInfo!==null"
                                style="margin-left: 15px"
                                size="mini"
                                @click="handleEdit(scope.row.bokeInfo.id)">查看文章
                            </el-button>
                            <el-button
                                size="mini"
                                type="danger"
                                @click="handleDelete(scope.row)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="footer">
                <el-button size="small" @click="totalSelection">
                    <span v-if="multipleSelection.length===tableData.length">取消</span>
                    <span v-else>全选</span>
                </el-button>
                <el-button size="small" :disabled="multipleSelection.length<2" @click="batchDelete">批量删除</el-button>
                <el-button size="small" :disabled="multipleSelection.length<2" @click="batchRecover">批量恢复
                </el-button>
                <el-button size="small" :disabled="multipleSelection.length<2" @click="batchForbidden">批量禁用
                </el-button>
                <div class="pagination">
                    <el-pagination
                        background
                        @current-change="currentChange"
                        layout="prev, pager, next"
                        :page-size="pageSize"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from "../plugins/config";
import moment from "moment"
import {mapState} from "vuex";
import FileManager from "@/views/FileManager";

export default {
    name: "List",
    data() {
        return {
            moment: moment,
            config: config,
            newName: "",
            extension: "",
            status: -1,
            statusOptions: [
                {
                    value: -1,
                    label: "全部"
                },
                {
                    value: 0,
                    label: "已禁用"
                },
                {
                    value: 1,
                    label: "正常"
                }
            ],
            pageSize: 10,
            pageNum: 0,
            total: 0,
            tableData: [],
            multipleSelection: []
        }
    },
    computed: {
        ...mapState([
            "screenHeight"
        ])
    },
    created() {
        this.loadList()
    },
    methods: {
        handleAvatarSuccess() {
            this.loadList()
        },
        loadList: function () {
            FileManager.list({
                pageSize: this.pageSize,
                pageNum: this.pageNum,
                newName: this.newName,
                extension: this.extension,
                status: this.status,
            }).then(res => {
                this.total = res.count
                this.tableData = res.list
            })
        },
        batchDelete: function () {
            this.$confirm('此操作将永久删除所选文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let idsArr = [];
                this.multipleSelection.forEach(res => {
                    idsArr.push(res.id)
                })
                FileManager.deleteByIds(idsArr.join(",")).then(() => {
                    this.loadList();
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                })
            })
        },
        batchRecover: function () {
            this.$confirm('此操作将恢复所选文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let idsArr = [];
                this.multipleSelection.forEach(res => {
                    idsArr.push(res.id)
                })
                FileManager.updateStatus(idsArr.join(","), 1).then(() => {
                    this.loadList();
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                })
            })
        },
        batchForbidden: function () {
            this.$confirm('此操作将禁用所选文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let idsArr = [];
                this.multipleSelection.forEach(res => {
                    idsArr.push(res.id)
                })
                FileManager.updateStatus(idsArr.join(","), 0).then(() => {
                    this.loadList();
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                })
            })
        },
        selectionChange: function (val) {
            this.multipleSelection = val
        },
        totalSelection: function () {
            this.tableData.forEach(row => {
                this.$refs.multipleTable.toggleAllSelection(row)
            })
        },
        resetAction: function () {
            this.pageNum = 0;
            this.pageSize = 10;
            this.newName = ""
            this.extension = ""
            this.status = -1
            this.loadList();
        },
        searchAction: function () {
            this.pageNum = 0;
            this.pageSize = 10;
            this.loadList();
        },
        statusChangeAction: function () {
            this.pageNum = 0;
            this.pageSize = 10;
            this.loadList();
        },
        handleForbidden: function (res) {
            this.$confirm('此操作将禁用所选文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                FileManager.updateStatus(res.id, 0).then(() => {
                    this.loadList();
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                })
            })
        },
        handleRecover: function (res) {
            this.$confirm('此操作将恢复所选文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log(res)
                FileManager.updateStatus(res.id, 1).then(() => {
                    this.loadList();
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                })
            })
        },
        handleDelete: function (res) {
            this.$confirm('此操作将永久删除所选文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                FileManager.deleteByIds(res.id).then(() => {
                    this.loadList();
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                })
            })
        },
        handleEdit: function (id) {
            this.$router.push({
                path: "/edit?id=" + id
            })
        },
        currentChange: function (index) {
            this.pageNum = index - 1;
            this.loadList();
        },
        indexMethod: function (index) {
            return (this.pageNum * this.pageSize) + index + 1;
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: white;

    .header {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        border-bottom: 1px #eeeeee solid;

        .tool-search {
            margin-left: 15px;
        }

        .tool-add {
            position: absolute;
            right: 15px;
            height: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .item {
            height: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .title {
                width: 80px;
                font-size: 15px;
            }

            .input {

            }
        }
    }

    .content {
        width: 100%;
        flex: 1;

        .headerStyle {
            background-color: red;
        }
    }

    .footer {
        width: 100%;
        margin-top: 5px;
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .pagination {
            position: absolute;
            right: 30px;
        }
    }
}
</style>
