<template>
    <div class="container">
        <div class="container_left">
            <div class="container_left_header">
                文章管理
            </div>
            <div class="container_left_menu">
                <div :class="menuIconIndex==='/edit' ?  'container_left_menu_item_s': 'container_left_menu_item'"
                     @click="toPageAction('/edit')">
                    <i class="iconfont menu-icon">&#xeb68;</i>
                    <span class="menu-title">文章发布</span>
                </div>
                <div
                    :class="menuIconIndex==='/bokeList' ?  'container_left_menu_item_s': 'container_left_menu_item'"
                    @click="toPageAction('/bokeList')">
                    <i class="iconfont menu-icon">&#xeb68;</i>
                    <span class="menu-title">文章列表</span>
                </div>
                <div
                    :class="menuIconIndex==='/fileList' ?  'container_left_menu_item_s': 'container_left_menu_item'"
                    @click="toPageAction('/fileList')">
                    <i class="iconfont menu-icon">&#xeb68;</i>
                    <span class="menu-title">文件列表</span>
                </div>
            </div>
        </div>
        <div class="container_content" :style="{width: screenWidth - (200) + 'px'}">
            <div class="header">
                <div class="left">
                    <i class="iconfont" @click="updateIsCollapse" style="font-size: 22px;cursor: pointer;">&#xe630;</i>
                    <span style="margin-left: 10px">{{ routeName }}</span>
                </div>
                <el-dropdown class="right" @command="handleCommand">
                      <span class="el-dropdown-link">
                        管理员<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="a">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="box" :style="{height: screenHeight-60 + 'px'}">
                <router-view
                    :style="{border: '1px #dddddd solid', width: '100%',height: screenHeight - 70 + 'px'}"/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "Index",
    data() {
        return {
            userId: "",
            routeName: '文章发布'
        }
    },
    watch: {
        $route(to) {
            this.routeName = to.name
        }
    },
    computed: {
        ...mapState([
            "screenWidth",
            "screenHeight",
            "menuIconIndex"
        ])
    },
    methods: {
        toPageAction: function (page) {
            this.$store.dispatch('setMenuIconIndex', page);
            this.$router.push({
                path: page
            })
        },
        updateIsCollapse: function () {
            this.$store.dispatch("setIsCollapse", !this.isCollapse)
        },
        handleCommand: function (res) {
            if (res === "a") {
                this.$store.dispatch("setUserInfo", null)
                this.$store.dispatch("setLogin", false)
                this.$router.push({
                    path: "/"
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100%;
    background-color: #eee;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .container_left {
        width: 200px;
        height: 100%;
        background-color: #1F232B;

        .container_left_header {
            height: 100px;
            width: 100%;
            color: white;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.5);
            padding-left: 30px;
            font-size: 20px;
            font-weight: bold;
        }

        .container_left_menu {
            margin-top: 10px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .container_left_menu_item {
                font-size: 15px;
                width: 100%;
                height: 55px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 30px;
                color: rgba(255, 255, 255, 0.8);
                cursor: pointer;
                border-left: 4px transparent solid;

                span {
                    margin-left: 15px;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.6);
                }
            }

            .container_left_menu_item_s {
                font-size: 15px;
                width: 100%;
                height: 55px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 30px;
                color: rgba(255, 255, 255, 0.8);
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.6);
                border-left: 4px cornflowerblue solid;

                span {
                    margin-left: 15px;
                }
            }
        }
    }

    .container_content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .header {
            width: 100%;
            height: 60px;
            border-bottom: 1px #dddddd solid;
            background-color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 15px;
            padding-right: 35px;
            font-weight: 400;
            font-size: 18px;

            .right {
                cursor: pointer;
                font-weight: 400;
                font-size: 15px;
            }
        }

        .box {
            width: 100%;
            flex: 1;
            padding: 5px;
            box-sizing: border-box;
        }
    }
}
</style>
